import {
  Flex,
  Box,
  Button,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import {
  ProductApproval,
  productApprovalsApi,
  ProductApprovalState,
} from '@texas/api/endpoints/productApprovalsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { Locale } from '@texas/i18n/types';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '@texas/components/shared/Icons';
import { fadeInAnimation } from '@texas/resources/animations/animations';
import {
  MetadataList,
  MetadataListRow,
} from '@texas/components/shared/MetadataList';
import placeholderImg from '@assets/placeholder-250.png';
import { SelectState } from './SelectState';
import { reactEvents } from '@bridge/reactEvents';
import { CompositionGroupView } from '../variant/compositions/shared/group/CompositionGroupView';
import { clientEndpoints } from '@texas/clientEndpoints';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';

export function ProductApprovalDetails({
  id,
  refetchAll,
}: {
  id: number;
  refetchAll: () => void;
}) {
  const {
    data: productApproval,
    refetch,
    error,
    loading,
  } = useApiResource(productApprovalsApi.get);
  const { t } = useTranslation();

  const triggerRefetch = useCallback(() => {
    refetch(id);
  }, [refetch, id]);

  useEffect(() => {
    refetch(id);
  }, [id, refetch]);

  useMemo(() => {
    return reactEvents.productApprovalChanged.subscribe((event) => {
      if (event.id === id) {
        triggerRefetch();
      }
    });
  }, [triggerRefetch, id]);

  return (
    <>
      {loading && (
        <Flex w="450px" justify="center" align="center">
          <Spinner thickness="3px" color="primary.200" size="lg" />
        </Flex>
      )}

      {!loading && (
        <Box
          animation={fadeInAnimation(400)}
          borderRadius={8}
          backgroundColor="texas.bg.900"
          minW="400px"
          _light={{ backgroundColor: 'white' }}
        >
          <ErrorLabel text={error?.message} />
          {productApproval && (
            <Tabs isLazy={true}>
              <TabList>
                <Tab>{t('productApproval.details')}</Tab>
                <Tab>{t('composition.composition')}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <ProductApprovalForm
                    refetchAll={() => {
                      refetchAll();
                      triggerRefetch();
                    }}
                    productApproval={productApproval}
                  />
                </TabPanel>
                <TabPanel as={Flex} justifyContent="center">
                  <CompositionGroupView
                    componentSettings={{
                      compositionArchiveRestore: 'hidden',
                      compositionEdit: 'hidden',
                      expander: 'hidden',
                      compositionGroupDots: 'hidden',
                      paStates: 'hidden',
                    }}
                    variantId={0}
                    compositionGroupId={productApproval.compositionGroupId}
                    productGroupId={0}
                    baseTemplateId={null}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </Box>
      )}
    </>
  );
}

export function ProductApprovalForm({
  productApproval,
  refetchAll,
}: {
  productApproval: ProductApproval;
  refetchAll: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Flex direction="column">
      <Image
        maxW="256px"
        fallbackSrc={placeholderImg}
        objectFit="contain"
        src={
          productApproval.imageIdentifier
            ? clientEndpoints.previewImage(productApproval.imageIdentifier, 256)
            : undefined
        }
        borderRadius={8}
      />

      <MetadataList>
        <MetadataListRow label={t('productApproval.number')}>
          {productApproval.formatNumber}
        </MetadataListRow>
        <MetadataListRow label={t('suppliers.supplier')}>
          {productApproval.supplierName}
        </MetadataListRow>
        <MetadataListRow label={t('general.branch')}>
          {productApproval.branchName}
        </MetadataListRow>
        <MetadataListRow label={t('general.option')}>
          {productApproval.variantName}
        </MetadataListRow>
        <MetadataListRow label={t('composition.compositionVersion')}>
          {t('composition.version', {
            nr: productApproval.compositionGroupVersion,
          })}
        </MetadataListRow>
        {productApproval.matrixNodeXId && (
          <MetadataListRow label={productApproval.matrixNodeXName}>
            {productApproval.matrixNodeXValue}
          </MetadataListRow>
        )}
        {productApproval.matrixNodeYId && (
          <MetadataListRow label={productApproval.matrixNodeYName}>
            {productApproval.matrixNodeYValue}
          </MetadataListRow>
        )}
        <MetadataListRow label={t('general.state')}>
          <Flex gap={2} alignItems="center">
            <Text>{productApproval.state}</Text>
            {convertToEnum(ProductApprovalState, productApproval.state) ===
              ProductApprovalState.Pending && (
              <SelectState
                onChanged={() => refetchAll()}
                id={productApproval.id}
                branchId={productApproval.branchId}
                variantId={productApproval.variantId}
              />
            )}
          </Flex>
        </MetadataListRow>
        <MetadataListRow label={t('article.created')}>
          {formatDate(Locale.En, productApproval.created)}
        </MetadataListRow>
        <MetadataListRow label={t('suppliers.supplierContact')}>
          {productApproval.supplierContactName}
        </MetadataListRow>
        <MetadataListRow label={t('customer.customerContact')}>
          {productApproval.customerContactName}
        </MetadataListRow>
        <MetadataListRow label={t('productApproval.issuedBy')}>
          {productApproval.issuedBy}
        </MetadataListRow>
      </MetadataList>
      <Flex pt={4} pb={2} gap={4}>
        <Button
          variant="texas-solid"
          rightIcon={<Icons.Download />}
          as="a"
          href={productApprovalsApi.downloadSupplierProductApproval(
            productApproval.id,
          )}
          target="_blank"
          download={true}
        >
          {t('productApproval.downloadSpa')}
        </Button>
        <Menu>
          <MenuButton
            as={Button}
            variant="texas-solid"
            rightIcon={<Icons.Download />}
          >
            {t('productApproval.downloadPa')}
          </MenuButton>
          <MenuList>
            <MenuItem
              as="a"
              target="_blank"
              download={true}
              href={productApprovalsApi.downloadCustomerProductApproval(
                productApproval.id,
                true,
              )}
            >
              {t('productApproval.excludeMaterialSpecifications')}
            </MenuItem>
            <MenuItem
              as="a"
              target="_blank"
              download={true}
              href={productApprovalsApi.downloadCustomerProductApproval(
                productApproval.id,
                false,
              )}
            >
              {t('productApproval.includeMaterialSpecifications')}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}
