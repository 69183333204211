import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { MatrixGrid } from '@texas/components/shared/matrix/MatrixGrid';
import { Checkbox } from '@chakra-ui/react';
import { useEffect } from 'react';
import { formatNodeGroup } from '@texas/utils/helpers/nodeGroupHelpers';
import { ProductApprovalCell } from '../../../types';
import { useProdutApprovalWizardState } from '../../../context';

export function SelectCells({
  articleNodeGroup,
  page,
}: {
  articleNodeGroup: ArticleNodeGroup;
  page: string;
}) {
  const { data, setData, setStepIsValid } = useProdutApprovalWizardState();

  useEffect(() => {
    if (
      Object.keys(data.selectedCells).filter(
        (key) => data.selectedCells[key].added,
      ).length > 0
    ) {
      setStepIsValid(page, true);
      return;
    }

    setStepIsValid(page, false);
  }, [data.selectedCells, setStepIsValid, page]);

  return (
    <MatrixGrid
      articleNodeGroup={articleNodeGroup}
      cell={(x, y, xIndex, yIndex) => {
        return (
          <Checkbox
            isChecked={cellChecked(data.selectedCells[cellFormat(x, y)])}
            onChange={(e) => {
              setData((d) => ({
                ...d,
                selectedCells: {
                  ...d.selectedCells,
                  [cellFormat(x, y)]: {
                    x,
                    y,
                    xIndex,
                    yIndex,
                    added: e.target.checked,
                  },
                },
              }));
            }}
          >
            {formatNodeGroup(
              articleNodeGroup.nodeXValues[xIndex],
              articleNodeGroup.nodeYValues[yIndex],
            )}
          </Checkbox>
        );
      }}
    />
  );
}

function cellChecked(cell: ProductApprovalCell | undefined) {
  return cell?.added ?? false;
}

function cellFormat(x: number, y: number) {
  return `${x}-${y}`;
}
