import { ProductApproval } from '@texas/api/endpoints/productApprovalsApi';
import {
  MetadataList,
  MetadataListRow,
} from '@texas/components/shared/MetadataList';
import useFormat from '@texas/hooks/useFormat';
import { useActiveSession } from '@texas/hooks/useSession';
import { useTranslation } from 'react-i18next';

export function PaMetadataList({
  isAccepted,
  pa,
}: {
  isAccepted: boolean;
  pa: ProductApproval;
}) {
  const session = useActiveSession();
  const { formatName } = useFormat();
  const { t } = useTranslation();
  return (
    <MetadataList>
      <MetadataListRow
        label={
          isAccepted
            ? t('productApproval.acceptedBy')
            : t('productApproval.rejectedBy')
        }
      >
        {formatName(
          session.currentUser.firstName,
          session.currentUser.lastName,
        )}
      </MetadataListRow>
      <MetadataListRow label={t('composition.compositionVersion')}>
        {pa.compositionGroupVersion}
      </MetadataListRow>
      <MetadataListRow label={t('suppliers.supplier')}>
        {pa.supplierName}
      </MetadataListRow>
      <MetadataListRow label={t('general.branch')}>
        {pa.branchName}
      </MetadataListRow>
      <MetadataListRow label={t('general.option')}>
        {pa.variantName}
      </MetadataListRow>
      {pa.matrixNodeXId && (
        <MetadataListRow label={pa.matrixNodeXName}>
          {pa.matrixNodeXValue}
        </MetadataListRow>
      )}
      {pa.matrixNodeYId && (
        <MetadataListRow label={pa.matrixNodeYName}>
          {pa.matrixNodeYValue}
        </MetadataListRow>
      )}
    </MetadataList>
  );
}
