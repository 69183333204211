import {
  Button,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SharedDisclosureProps } from './types';
import { useEffect, useState } from 'react';
import { ErrorDetails } from './alert/ErrorDetails';
import { Icons } from './Icons';
import { ReactSelectOption, ServerError } from '@texas/types';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { articlesApi, UpdateVariant } from '@texas/api/endpoints/articlesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { request } from '@texas/utils/helpers/httpHelpers';
import { blinkAnimation } from '@texas/resources/animations/animations';
import { categoryCodesApi } from '@texas/api/endpoints/metadata/categoryCodesApi';
import { TexasFormLabel } from './form/TexasFormLabel';
import { TexasSelect } from './form/TexasSelect';

export interface ChangeCategoryProps extends SharedDisclosureProps {
  onCategoryCodeChanged: () => void;
  variants: UpdateVariant[] | null;
}

export function ChangeCategoryCode(props: ChangeCategoryProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const {
    request: updateCategoryCodeRequest,
    error,
    loading,
  } = useApiRequest(articlesApi.updateCategoryCode);

  const {
    data: categoryCodeNames,
    refetch: refetchCategoryCodes,
    loading: categoryCodesLoading,
  } = useApiResource(categoryCodesApi.getSharedNames);

  const [newCategoryCode, setNewCategoryCode] = useState<string>();

  const {
    data: variants,
    refetch: refetchVariants,
    loading: variantsLoading,
    set: setVariants,
  } = useApiResource(articlesApi.getUpdateVariants);

  useEffect(() => {
    setNewCategoryCode(undefined);
    setVariants(null);
    if (!props.variants) return;
    refetchVariants({ variants: props.variants });
  }, [props.variants, refetchVariants, setVariants]);

  useEffect(() => {
    refetchCategoryCodes(variants?.map((v) => v.branchId));
  }, [refetchCategoryCodes, variants]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('article.changeCategoryCode')}</ModalHeader>
        <ModalBody>
          <ErrorDetails error={error} />
          <Flex dir="column" justify="center">
            {variantsLoading && <Spinner />}
            {!variantsLoading && (
              <TableContainer flexGrow={1}>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>{t('general.itemCode')}</Th>
                      <Th>{t('general.option')}</Th>
                      <Th>{t('general.branch')}</Th>
                      <Th>{t('article.categoryCode')}</Th>
                      <Th />
                      <Th fontWeight="bold">{t('article.newCategoryCode')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {variants?.map((x, i) => {
                      return (
                        <Tr key={i}>
                          <Td>{x.itemCode}</Td>
                          <Td>{x.name}</Td>
                          <Td>{x.branchIdentifier}</Td>
                          <Td>{x.categoryCode}</Td>
                          <Td>
                            <Icons.ArrowRight />
                          </Td>
                          <Td
                            animation={blinkAnimation()}
                            color="texas.sand.100"
                            _light={{ color: 'texas.burntSienna.500' }}
                            fontWeight="bold"
                          >
                            {newCategoryCode ? newCategoryCode : ''}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Flex>
          <Flex my={4}>
            <FormControl>
              <TexasFormLabel>{t('article.newCategoryCode')}</TexasFormLabel>
              <TexasSelect
                isLoading={categoryCodesLoading}
                isClearable={true}
                value={
                  newCategoryCode
                    ? { label: newCategoryCode, value: newCategoryCode }
                    : null
                }
                onChange={(e) => setNewCategoryCode(e?.value)}
                options={categoryCodeNames?.map<ReactSelectOption>((x) => ({
                  label: x.name,
                  value: x.name,
                }))}
              />
              {variants &&
                [...new Set(variants.map((v) => v.branchId))].length > 1 && (
                  <Text variant="sub">{t('article.categoryCodeInfoNote')}</Text>
                )}
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter
          backgroundColor="gray.800"
          _light={{ backgroundColor: 'gray.50' }}
        >
          <Button variant="texas-outline-light" onClick={props.onClose}>
            {t('general.close')}
          </Button>
          <Button
            variant="texas-solid"
            ml={3}
            isLoading={loading}
            isDisabled={loading || !newCategoryCode}
            onClick={async () => {
              if (!newCategoryCode) return;

              await request(
                updateCategoryCodeRequest,
                [
                  {
                    variants: variants ?? [],
                    categoryCode: newCategoryCode,
                  },
                ],
                (_) => {
                  toast({
                    title: t('general.updated'),
                    status: 'success',
                    isClosable: true,
                  });
                  props.onCategoryCodeChanged();
                  props.onClose();
                  setNewCategoryCode(undefined);
                },
                (error: ServerError) => {
                  toast({
                    title: t('general.updateFailed'),
                    description: error.message,
                    status: 'error',
                    isClosable: true,
                  });
                },
              );
            }}
          >
            {t('article.changeCategoryCode')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
