import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = config.definePartsStyle({
  thead: {
    th: {
      border: 'none',
      borderRadius: 0,
      fontFamily: 'Instrument Sans',
      paddingX: 2,
    },
  },
  tbody: {
    tr: {
      borderBottom: '1px solid',
      borderColor: 'whiteAlpha.200',
      _light: { borderColor: 'blackAlpha.200' },
    },
    td: {
      paddingY: 1,
      paddingX: 2,
    },
  },
});

const variants = {
  stickyHeader: {
    thead: {
      backgroundColor: 'texas.bg.800',
      opacity: 0.95,
      pos: 'sticky',
      top: 0,
      zIndex: 1,
      borderBottom: 0,
      _light: {
        backgroundColor: 'gray.50',
        borderColor: 'gray.50',
      },
    },
  },
  salesTiers: {
    thead: {
      td: {
        padding: '0 0 1em 0',
        fontWeight: 'bold',
        verticalAlign: 'baseline',
      },
    },
    tbody: {
      tr: {
        borderBottom: '1px solid',
        borderBottomColor: 'gray.800',
        _hover: { background: 'whiteAlpha.100' },
        _light: {
          borderBottomColor: 'gray.50',
          _hover: { background: 'blackAlpha.100' },
        },
        td: { padding: '0.4em', transition: 'background-color 200ms ease' },
      },
    },
    tfoot: {
      td: {
        padding: '1em 0 0 0',
        fontWeight: 'bold',
        fontSize: '12px',
      },
    },
  },
  branchOverview: {
    table: { bg: 'texas.bg.blueTint.100', _light: { bg: 'white' } },
    thead: {
      border: 'none',
      bg: 'texas.bg.blueTint.100',
      _light: { bg: 'white' },
      tr: {
        color: 'white',
        borderBottom: '1px solid',
        borderBottomColor: 'texas.bg.blueTint.50',
        _hover: { bg: 'texas.bg.blueTint.100' },
        _light: { color: 'texas.bg.900', _hover: { bg: 'white' } },
      },
    },
    tbody: {
      tr: {
        borderBottom: '2px solid',
        borderBottomColor: 'texas.bg.blueTint.100',
        color: 'white',
        _hover: { background: 'whiteAlpha.100' },
        _light: {
          color: 'texas.bg.900',
          borderBottomColor: 'white',
          _hover: { background: 'blackAlpha.100' },
        },
      },
      td: { padding: '0.2em' },
      th: { padding: '0.2em' },
    },
  },
  configuration: {
    tbody: {
      td: { fontSize: '14px', verticalAlign: 'baseline' },
    },
  },
  striped: {
    table: {
      size: 'sm',
      w: 'full',
      maxHeight: '380px',
      overflowY: 'auto',
      overflowX: 'hidden',
      colorScheme: 'gray',
    },
    thead: {
      pos: 'sticky',
      top: '0',
      zIndex: 1,
      background: 'texas.bg.800',
    },
    th: {
      color: 'white',
      px: '2',
    },
  },
  'select-product-approval': {
    table: { width: 'full' },
    thead: {
      border: 'none',
    },
    th: {
      p: '4px',
      fontSize: '14px',
    },
    td: { p: '4px', fontSize: '14px' },
    tbody: {
      tr: {
        _hover: {
          backgroundColor: 'texas.bg.700',
          _light: { backgroundColor: 'gray.50' },
        },
      },
    },
  },
};

export default config.defineMultiStyleConfig({ baseStyle, variants });
