import {
  Text,
  Flex,
  GridItem,
  Grid,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
} from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { BodyOverride } from '@texas/components/shared/BodyOverride';
import { useEffect, useRef, useState } from 'react';
import { MatrixView } from './matrix/MatrixView';
import {
  formatNodeGroup,
  nodeGroupIsPopulated,
} from '@texas/utils/helpers/nodeGroupHelpers';
import { useTranslation } from 'react-i18next';
import { TemplateCard } from './shared/composition/TemplateCard';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import { CompositionGroupView } from './shared/group/CompositionGroupView';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroupApi';

interface CompositionsProps {
  variantId: number;
  productGroupId: number;
  articleNodeGroup: ArticleNodeGroup;
}

export function Compositions({
  variantId,
  productGroupId,
  articleNodeGroup,
}: CompositionsProps) {
  return (
    <BodyOverride>
      {!nodeGroupIsPopulated(articleNodeGroup) ? (
        <StandardComposition
          variantId={variantId}
          productGroupId={productGroupId}
        />
      ) : (
        <MatrixComposition
          variantId={variantId}
          productGroupId={productGroupId}
          articleNodeGroup={articleNodeGroup}
        />
      )}
    </BodyOverride>
  );
}

function StandardComposition({
  variantId,
  productGroupId,
}: {
  variantId: number;
  productGroupId: number;
}) {
  const { t } = useTranslation();
  const { data: suppliers, refetch: refetchSuppliers } = useApiResource(
    variantsApi.getVariantBranchSuppliers,
  );

  const [baseTemplateId, setBaseTemplateId] = useState<number | null>(null);

  useEffect(() => {
    refetchSuppliers(variantId);
  }, [refetchSuppliers, variantId]);

  return (
    <Tabs variant="line" isLazy={true}>
      <Grid
        gridTemplateRows="auto 1fr"
        gridTemplateColumns="auto 1fr"
        columnGap={8}
      >
        <GridItem gridRow={1} gridColumn={2}>
          <TabList>
            {suppliers?.branches.map((x) => {
              return (
                <Tab key={x.id}>
                  <Flex flexDir="column" align="start">
                    <Text variant="tab-header">{x.identifier}</Text>
                    <Text variant="sub">{x.name}</Text>
                  </Flex>
                </Tab>
              );
            })}
          </TabList>
        </GridItem>
        <GridItem gridRow={2} gridColumn={1} padding={4}>
          <CompositionGroupView
            customTitle={t('composition.variantTemplate')}
            canBeReplaced={false}
            onLoaded={(t) => setBaseTemplateId(t?.exists ? t.id : null)}
            variantId={variantId}
            productGroupId={productGroupId}
            baseTemplateId={baseTemplateId}
          />
        </GridItem>
        <GridItem gridRow={2} gridColumn={2} overflow="auto">
          <TabPanels>
            {suppliers?.branches.map((x) => {
              return (
                <TabPanel key={x.id}>
                  <Flex gap={2}>
                    {suppliers.suppliers
                      .filter((s) => s.branchId === x.id)
                      .map((s) => {
                        return (
                          <CompositionGroupView
                            key={s.supplierId}
                            variantId={variantId}
                            supplierId={s.supplierId}
                            branchId={s.branchId}
                            productGroupId={productGroupId}
                            customTitle={s.supplierName}
                            baseTemplateId={baseTemplateId}
                          />
                        );
                      })}
                  </Flex>
                </TabPanel>
              );
            })}
          </TabPanels>
        </GridItem>
      </Grid>
    </Tabs>
  );
}

function MatrixComposition({
  variantId,
  productGroupId,
  articleNodeGroup,
}: {
  variantId: number;
  productGroupId: number;
  articleNodeGroup: ArticleNodeGroup;
}) {
  const { data: suppliers, refetch: refetchSuppliers } = useApiResource(
    variantsApi.getVariantBranchSuppliers,
  );

  useEffect(() => {
    refetchSuppliers(variantId);
  }, [refetchSuppliers, variantId]);
  const [baseTemplateId, setBaseTemplateId] = useState<number | null>(null);
  const [selectedCell, setSelectedCell] = useState<
    [number, number, number, number] | null
  >(null);
  const { t } = useTranslation();
  const { data, refetch, loading } = useApiResource(
    compositionGroupApi.getAllCellGroups,
  );
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    refetch(variantId);
  }, [refetch, variantId]);

  useEffect(() => {
    if (!selectedCell) return;
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedCell]);

  return (
    <>
      <Box bg="texas.bg.980" _light={{ bg: 'gray.50' }} mb={6}>
        <MatrixView
          cells={data}
          refetchCells={() => refetch(variantId)}
          cellsLoading={loading}
          selectedCell={selectedCell}
          onCellSelected={(cell) => {
            setSelectedCell(cell);
          }}
          variantId={variantId}
          articleNodeGroup={articleNodeGroup}
          baseTemplateId={baseTemplateId}
        />
      </Box>

      <Tabs variant="line" isLazy={true} ref={ref}>
        <Grid
          gridTemplateRows="auto 1fr"
          gridTemplateColumns="auto 1fr"
          columnGap={8}
        >
          <GridItem gridRow={1} gridColumn={2}>
            <TabList>
              {suppliers?.branches.map((x) => {
                return (
                  <Tab key={x.id}>
                    <Flex flexDir="column" align="start">
                      <Text variant="tab-header">{x.identifier}</Text>
                      <Text variant="sub">{x.name}</Text>
                    </Flex>
                  </Tab>
                );
              })}
            </TabList>
          </GridItem>
          <GridItem gridRow={2} gridColumn={1} padding={4}>
            <Flex gap={2}>
              <CompositionGroupView
                canBeReplaced={false}
                customTitle={t('composition.variantTemplate')}
                onLoaded={(t) => setBaseTemplateId(t?.exists ? t.id : null)}
                variantId={variantId}
                productGroupId={productGroupId}
                baseTemplateId={baseTemplateId}
              />
              {selectedCell ? (
                <CompositionGroupView
                  onUpdated={() => refetch(variantId)}
                  variantId={variantId}
                  productGroupId={productGroupId}
                  baseTemplateId={baseTemplateId}
                  cellX={selectedCell[0]}
                  cellY={selectedCell[1]}
                  customTitle={formatNodeGroup(
                    articleNodeGroup.nodeXValues[selectedCell[2]],
                    articleNodeGroup.nodeYValues[selectedCell[3]],
                    true,
                  )}
                  articleNodeGroup={articleNodeGroup}
                />
              ) : (
                <Flex flexDir="column" height="full" gap={2}>
                  <Box>
                    <Text textAlign="center">
                      {t('composition.baseCellTemplate')}
                    </Text>
                    <Text textAlign="center" color="gray.300" fontSize="sm">
                      {t('composition.noCellSelected')}
                    </Text>
                  </Box>
                  <TemplateCard exists={false}>
                    <Text m="auto">{t('composition.noCellSelected')}</Text>
                  </TemplateCard>
                </Flex>
              )}
            </Flex>
          </GridItem>
          <GridItem gridRow={2} gridColumn={2} overflow="auto">
            {selectedCell && (
              <TabPanels>
                {suppliers?.branches.map((x) => {
                  return (
                    <TabPanel key={x.id}>
                      <Flex gap={2}>
                        {suppliers.suppliers
                          .filter((s) => s.branchId === x.id)
                          .map((s) => {
                            return (
                              <CompositionGroupView
                                onUpdated={() => refetch(variantId)}
                                key={s.supplierId}
                                variantId={variantId}
                                productGroupId={productGroupId}
                                supplierId={s.supplierId}
                                branchId={s.branchId}
                                customTitle={s.supplierName}
                                cellX={selectedCell[0]}
                                cellY={selectedCell[1]}
                                baseTemplateId={baseTemplateId}
                                articleNodeGroup={articleNodeGroup}
                              />
                            );
                          })}
                      </Flex>
                    </TabPanel>
                  );
                })}
              </TabPanels>
            )}
          </GridItem>
        </Grid>
      </Tabs>
    </>
  );
}
