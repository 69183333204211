import {
  Grid,
  GridItem,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  MenuDivider,
  useDisclosure,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '@texas/components/shared/Icons';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { GroupContext } from './CompositionGroupView';
import { CompositionListModal } from '../../CompositionListModal';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';

export function CompositionGroupFooter({
  articleNodeGroup,
}: {
  articleNodeGroup?: ArticleNodeGroup;
}) {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isArchiveOpen,
    onClose: onArchiveClose,
    onOpen: onArchiveOpen,
  } = useDisclosure();
  const {
    isOpen: isNewGroupOpen,
    onClose: onNewGroupClose,
    onOpen: onNewGroupOpen,
  } = useDisclosure();

  const {
    group,
    archiveGroupRequest,
    addCompositionToGroupRequest,
    createGroupRequest,
    baseTemplateId,
    canBeReplaced,
    copyDisclosure,
    selectCellDisclosure,
    refetchGroup,
    anyProductApproval,
    copyCompositionGroupRequest,
  } = useContext(GroupContext)!;

  return (
    <>
      <Grid columnGap="2">
        <GridItem colStart={1}>
          <Flex gap={2}>
            <Menu placement="top">
              <MenuButton
                as={IconButton}
                variant="texas-light"
                icon={<Icons.DotsHorizontal />}
              />
              <MenuList color="white">
                <MenuItem
                  isDisabled={anyProductApproval}
                  icon={<Icons.Plus boxSize={5} />}
                  onClick={() => {
                    addCompositionToGroupRequest();
                  }}
                >
                  {t('composition.addComposition')}
                </MenuItem>
                <MenuItem
                  icon={<Icons.Archive boxSize={5} />}
                  isDisabled={!group?.hasArchivedCompositions}
                  onClick={onOpen}
                >
                  {t('composition.showArchivedCompositions')}
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  icon={<Icons.Plus boxSize={5} />}
                  onClick={onNewGroupOpen}
                >
                  {t('composition.createNewGroup')}
                </MenuItem>
                {anyProductApproval && (
                  <MenuItem
                    icon={<Icons.ArrowRightTopBold boxSize={5} />}
                    onClick={() => {
                      copyCompositionGroupRequest(group!.id);
                    }}
                  >
                    {t('composition.copyIntoNewVersion')}
                  </MenuItem>
                )}
                {canBeReplaced && (
                  <>
                    <MenuItem
                      icon={<Icons.ArrowRightTopBold boxSize={5} />}
                      isDisabled={!baseTemplateId || anyProductApproval}
                      onClick={() =>
                        copyDisclosure.onOpen({
                          fromCompositionGroupId: baseTemplateId!,
                          copyTitle: t('composition.fromVariantBase'),
                        })
                      }
                    >
                      {t('composition.replaceVariantTemplate')}
                    </MenuItem>
                    {articleNodeGroup && (
                      <MenuItem
                        icon={<Icons.ArrowRightTopBold boxSize={5} />}
                        isDisabled={
                          group?.archived === undefined || anyProductApproval
                        }
                        onClick={() => {
                          selectCellDisclosure.onOpen();
                        }}
                      >
                        {t('composition.replaceCellTemplate')}
                      </MenuItem>
                    )}
                  </>
                )}
                {!group?.archived && (
                  <MenuItem
                    color="texas.sand.50"
                    icon={<Icons.Archive boxSize={5} />}
                    onClick={onArchiveOpen}
                  >
                    {t('composition.archiveGroup')}
                  </MenuItem>
                )}
                <MenuItem isDisabled={true} icon={<Icons.Grid boxSize={5} />}>
                  {t('composition.showAllVersions')}
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </GridItem>
      </Grid>
      <CompositionListModal
        onRestored={refetchGroup}
        isOpen={isOpen}
        onClose={onClose}
        compositionGroupId={group!.id}
      />
      <VerifyDialogWithFunction
        headerTitle={t('composition.archiveGroup')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        toPerform={archiveGroupRequest}
        isOpen={isArchiveOpen}
        onClose={onArchiveClose}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithFunction>
      <VerifyDialogWithFunction
        headerTitle={t('composition.createNewGroup')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        toPerform={createGroupRequest}
        isOpen={isNewGroupOpen}
        onClose={onNewGroupClose}
      >
        {t('composition.createNewGroupDesc')}
      </VerifyDialogWithFunction>
    </>
  );
}
