import {
  CompositionMaterial,
  UpdateCompositionMaterialRequest,
  compositionApi,
} from '@texas/api/endpoints/compositionApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { CompositionMaterialForm } from './CompositionMaterialForm';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';

interface UpdateCompositionMaterialProps extends SharedDisclosureProps {
  compositionMaterialId: number;
  productGroupId: number;
  onUpdate: (data: CompositionMaterial) => void;
}

export function UpdateCompositionMaterial({
  compositionMaterialId,
  productGroupId,
  isOpen,
  onClose,
  onUpdate,
}: UpdateCompositionMaterialProps) {
  const { request: updateRequest } = useApiRequest(
    compositionApi.updateCompositionMaterial,
  );

  const toast = useToast();
  const { t } = useTranslation();

  const {
    data,
    refetch,
    loading,
    error: getError,
  } = useApiResource(compositionApi.getCompositionMaterial);

  useEffect(() => {
    if (!isOpen) return;
    refetch(compositionMaterialId);
  }, [compositionMaterialId, refetch, isOpen]);

  const onSubmit = async (data: UpdateCompositionMaterialRequest) =>
    await request(
      updateRequest,
      [compositionMaterialId, data],
      (response) => {
        onUpdate(response);
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  if (getError) {
    return <ErrorDetails error={getError} />;
  }

  return (
    <CompositionMaterialForm
      loading={loading}
      compositionMaterial={data}
      onClose={onClose}
      isOpen={isOpen}
      productGroupId={productGroupId}
      onFormSubmit={(data) => onSubmit(data)}
    />
  );
}
