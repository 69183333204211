import {
  GridItem,
  Grid,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import { useProductApprovals } from './useProductApprovals';
import { useTranslation } from 'react-i18next';
import { ProductApprovalList } from './ProductApprovalsList';
import { PreviewProductApproval } from './PreviewProductApproval';
import {
  GeneratedProductApprovalState,
  GeneratedProductApproval,
} from '../../../types';
import { useEffect, useState } from 'react';
import { useProdutApprovalWizardState } from '../../../context';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';

export function SelectProductApprovals({ page }: { page: string }) {
  const { t } = useTranslation();
  const { data, setData, setStepIsValid } = useProdutApprovalWizardState();
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (
      data.generatedProductApprovalKeys.length > 0 &&
      data.generatedProductApprovalKeys.filter(
        (key) =>
          data.generatedProductApprovals[key].state ===
          GeneratedProductApprovalState.Unhandled,
      ).length === 0
    ) {
      setStepIsValid(page, true);
      return;
    }
    setStepIsValid(page, false);
  }, [
    data.generatedProductApprovalKeys,
    data.generatedProductApprovalKeys.length,
    data.generatedProductApprovals,
    page,
    setStepIsValid,
  ]);

  const { setSelectedKey, selectedKey, previousRelevantPaRef } =
    useProductApprovals();

  return (
    <Grid gridTemplateColumns="40% 60%" gap={6}>
      <GridItem>
        <Tabs
          onChange={(e) => {
            setSelectedKey('');
            handleTabsChange(e);
          }}
          index={tabIndex}
          maxH="780px"
          overflowY="auto"
        >
          <TabList pos="sticky" top={0} zIndex={1}>
            <Tab>
              {t('wizard.productApproval.unhandled', {
                count: filterProductApprovals(
                  data.generatedProductApprovals,
                  data.generatedProductApprovalKeys,
                  GeneratedProductApprovalState.Unhandled,
                ).length,
              })}
            </Tab>
            <Tab>
              {t('wizard.productApproval.countOk', {
                count: filterProductApprovals(
                  data.generatedProductApprovals,
                  data.generatedProductApprovalKeys,
                  GeneratedProductApprovalState.Accepted,
                ).length,
              })}
            </Tab>
            <Tab>
              {t('wizard.productApproval.ignored', {
                count: filterProductApprovals(
                  data.generatedProductApprovals,
                  data.generatedProductApprovalKeys,
                  GeneratedProductApprovalState.Ignored,
                ).length,
              })}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <ProductApprovalList
                nodeGroups={data.articleNodes}
                onHandle={(key, state) => handlePa(key, state)}
                selectedKey={selectedKey}
                onClick={(k) => setSelectedKeyFunc(k)}
                productApprovals={filterProductApprovals(
                  data.generatedProductApprovals,
                  data.generatedProductApprovalKeys,
                  GeneratedProductApprovalState.Unhandled,
                )}
              />
            </TabPanel>
            <TabPanel p={0}>
              <ProductApprovalList
                nodeGroups={data.articleNodes}
                onHandle={(key, state) => handlePa(key, state)}
                selectedKey={selectedKey}
                onClick={(k) => setSelectedKeyFunc(k)}
                productApprovals={filterProductApprovals(
                  data.generatedProductApprovals,
                  data.generatedProductApprovalKeys,
                  GeneratedProductApprovalState.Accepted,
                )}
              />
            </TabPanel>
            <TabPanel p={0}>
              <ProductApprovalList
                nodeGroups={data.articleNodes}
                onHandle={(key, state) => handlePa(key, state)}
                selectedKey={selectedKey}
                onClick={(k) => setSelectedKeyFunc(k)}
                productApprovals={filterProductApprovals(
                  data.generatedProductApprovals,
                  data.generatedProductApprovalKeys,
                  GeneratedProductApprovalState.Ignored,
                )}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </GridItem>
      <GridItem>
        {productApprovalExists(selectedKey, data.generatedProductApprovals) ? (
          <PreviewProductApproval
            productApproval={data.generatedProductApprovals[selectedKey]}
            selectedKey={selectedKey}
            onHandle={(key, state) => handlePa(key, state)}
            previousReleventPaRef={previousRelevantPaRef.current}
          />
        ) : (
          <Text variant="sub" align="center">
            {t('productApproval.selectToShowPreview')}
          </Text>
        )}
      </GridItem>
    </Grid>
  );

  function handlePa(key: string, state: GeneratedProductApprovalState) {
    const stateFromTab = GeneratedProductApprovalState[tabIndex];
    let index = -1;
    let previousUnhandledPaIndex = -1;
    for (let i = 0; i < data.generatedProductApprovalKeys.length; i++) {
      const paKey = data.generatedProductApprovalKeys[i];

      if (paKey === key) {
        index = i;
      }

      if (
        data.generatedProductApprovals[paKey].state ===
          convertToEnum(GeneratedProductApprovalState, stateFromTab) &&
        paKey !== key
      ) {
        previousUnhandledPaIndex = i;
        if (previousUnhandledPaIndex > index && index !== -1) {
          break;
        }
      }
    }

    if (
      previousUnhandledPaIndex !== index &&
      previousUnhandledPaIndex !== -1 &&
      previousUnhandledPaIndex < data.generatedProductApprovalKeys.length
    ) {
      setSelectedKeyFunc(
        data.generatedProductApprovalKeys[previousUnhandledPaIndex],
      );
    } else {
      setSelectedKey('');
      if (tabIndex === 0) {
        setTabIndex(1);
      }
    }

    setData((d) => ({
      ...d,
      generatedProductApprovals: {
        ...d.generatedProductApprovals,
        [key]: {
          ...d.generatedProductApprovals[key],
          state: state,
        },
      },
    }));
  }

  function setSelectedKeyFunc(key: string) {
    setSelectedKey(key);

    previousRelevantPaRef.current = previousRelevantPa();

    function previousRelevantPa() {
      const currentPa = data.generatedProductApprovals[key];

      const keyIndex =
        data.generatedProductApprovalKeys.findIndex((x) => x === key) - 1;

      for (let i = keyIndex; i >= 0; i--) {
        const pa =
          data.generatedProductApprovals[data.generatedProductApprovalKeys[i]];
        if (pa.supplier.id === currentPa.supplier.id) {
          return pa;
        }
      }

      return null;
    }
  }
}

function productApprovalExists(
  key: string,
  productApprovals: Record<string, GeneratedProductApproval | undefined>,
) {
  return productApprovals[key] !== undefined;
}

function filterProductApprovals(
  productApprovals: Record<string, GeneratedProductApproval>,
  keys: string[],
  state: GeneratedProductApprovalState,
) {
  return keys.map((k) => productApprovals[k]).filter((p) => p.state === state);
}
