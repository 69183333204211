import { nodeGroupIsPopulated } from '@texas/utils/helpers/nodeGroupHelpers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useActiveSession } from '@texas/hooks/useSession';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import {
  GeneratedProductApproval,
  GeneratedProductApprovalState,
} from '../../../types';
import { useProdutApprovalWizardState } from '../../../context';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroupApi';

export function useProductApprovals() {
  const { data, setData } = useProdutApprovalWizardState();
  const { currentUser } = useActiveSession();
  const [selectedKey, setSelectedKey] = useState<string>('');
  const previousRelevantPaRef = useRef<GeneratedProductApproval | null>();
  const refKeys = useRef(data.generatedProductApprovalKeys);
  const refPas = useRef(data.generatedProductApprovals);

  const { data: compositions, refetch } = useApiResource(
    compositionGroupApi.searchGroup,
  );

  useEffect(() => {
    refetch({
      variantId: data.variantId,
      branchIds: data.selectedBranches.map((x) => x.id),
      supplierIds: data.selectedSuppliers.map((x) => x.id),
    });
  }, [data.selectedBranches, data.selectedSuppliers, data.variantId, refetch]);

  const generateProductApprovals = useCallback(() => {
    if (!compositions) return;

    const productApprovals: Record<string, GeneratedProductApproval> = {};
    const keys: string[] = [];

    for (let k = 0; k < data.selectedSuppliers.length; k++) {
      const supplier = data.selectedSuppliers[k];

      if (nodeGroupIsPopulated(data.articleNodes)) {
        const cellKeys = Object.keys(data.selectedCells);
        for (let i = 0; i < cellKeys.length; i++) {
          const cell = data.selectedCells[cellKeys[i]];

          if (!cell.added) continue;

          const format = `${supplier.id}-${supplier.branch.id}-${cell.x}-${cell.y}`;

          if (refKeys.current.includes(format)) {
            keys.push(format);
            productApprovals[format] = refPas.current[format];
            continue;
          }

          const supplierCellComposition = compositions.find(
            (c) =>
              c.supplierId === supplier.id &&
              c.branchId === supplier.branch.id &&
              c.cellX === cell.x &&
              c.cellY === cell.y,
          );

          if (refKeys.current.includes(format)) {
            keys.push(format);
            productApprovals[format] = {
              ...refPas.current[format],
              compositionGroup: supplierCellComposition ?? null,
            };
            continue;
          }

          keys.push(format);
          productApprovals[format] = {
            key: format,
            branch: supplier.branch,
            cell: {
              x: cell.x,
              y: cell.y,
              xIndex: cell.xIndex,
              yIndex: cell.yIndex,
              added: true,
            },
            customerContact: null,
            issuedBy: {
              id: currentUser.id,
              name: `${currentUser.firstName} ${currentUser.lastName}`,
            },
            supplier: supplier,
            supplierContact: null,
            supplierExternalItemCode: '',
            supplierNote: '',
            state: GeneratedProductApprovalState.Unhandled,
            compositionGroup: supplierCellComposition ?? null,
          };
        }

        continue;
      }

      const format = `${supplier.id}-${supplier.branch.id}`;
      const supplierComposition = compositions.find(
        (c) =>
          c.supplierId === supplier.id && c.branchId === supplier.branch.id,
      );

      if (refKeys.current.includes(format)) {
        keys.push(format);
        productApprovals[format] = {
          ...refPas.current[format],
          compositionGroup: supplierComposition ?? null,
        };
        continue;
      }

      keys.push(format);
      productApprovals[format] = {
        key: format,
        branch: supplier.branch,
        cell: null,
        customerContact: null,
        issuedBy: {
          id: currentUser.id,
          name: `${currentUser.firstName} ${currentUser.lastName}`,
        },
        supplier: supplier,
        supplierContact: null,
        supplierExternalItemCode: '',
        supplierNote: '',
        state: GeneratedProductApprovalState.Unhandled,
        compositionGroup: supplierComposition ?? null,
      };
    }

    if (keys.length > 0) {
      setSelectedKey(keys[0]);
    }

    refKeys.current = keys;
    refPas.current = productApprovals;
    setData((d) => ({
      ...d,
      generatedProductApprovals: productApprovals,
      generatedProductApprovalKeys: keys,
    }));
  }, [
    compositions,
    setData,
    data.selectedSuppliers,
    data.articleNodes,
    data.selectedCells,
    currentUser.id,
    currentUser.firstName,
    currentUser.lastName,
  ]);

  useEffect(() => {
    generateProductApprovals();
  }, [generateProductApprovals]);

  useEffect(() => {
    refKeys.current = data.generatedProductApprovalKeys;
    refPas.current = data.generatedProductApprovals;
  }, [data.generatedProductApprovalKeys, data.generatedProductApprovals]);

  return {
    selectedKey,
    setSelectedKey,
    previousRelevantPaRef,
  };
}
