import { httpClient } from '../httpClient/httpClient';
import { Composition } from './compositionApi';
import { ProductApprovalState } from './productApprovalsApi';

interface CreateCompositionGroupRequest {
  variantId: number;
  supplierId?: number;
  branchId?: number;
  cellX?: number;
  cellY?: number;
}

type CopyCompositionGroupRequest = CreateCompositionGroupRequest;

interface UpdateCompositionGroupRequest {
  note: string;
}

interface CompositionGroupPa {
  productApprovalId: number;
  state: ProductApprovalState;
}

export interface CompositionGroup {
  articleId: number;
  id: number;
  variantId: number;
  cellX: number | null;
  cellY: number | null;
  supplierId: number | null;
  branchId: number | null;
  note: string;
  version: number;
  isTemplate: boolean;
  exists: boolean;
  compositions: Composition[];
  productApprovals: CompositionGroupPa[] | null;
  archived: string | null;
  imageIdentifier: string;
  hasArchivedCompositions: boolean;
}

interface LocateCompositionGroupParams {
  variantId: number;
  cellX?: number;
  cellY?: number;
  supplierId?: number;
  branchId?: number;
}

interface SearchCompositionGroupsParams {
  variantId: number;
  branchIds: number[];
  supplierIds: number[];
}

export interface CompositionCell {
  x: number;
  y: number;
  id: number;
  colorCodes: string[];
}

interface GetCompositionGroupParams {
  includeArchived: boolean;
}

export const compositionGroupApi = {
  addGroup: (data: CreateCompositionGroupRequest) =>
    httpClient.post<CompositionGroup>(`/compositiongroups`, {
      data,
    }),
  copyGroup: (from: number, data: CopyCompositionGroupRequest) =>
    httpClient.put<CompositionGroup>(`/compositiongroups/${from}/copy`, {
      data,
    }),
  updateGroup: (id: number, data: UpdateCompositionGroupRequest) =>
    httpClient.put<CompositionGroup>(`/compositiongroups/${id}`, { data }),
  getGroup: (id: number, params?: GetCompositionGroupParams) =>
    httpClient.get<CompositionGroup>(`/compositiongroups/${id}`, { params }),
  getAllCellGroups: (variantId: number) =>
    httpClient.get<CompositionCell[]>(`compositiongroups/${variantId}/cells`),
  searchGroup: (params: SearchCompositionGroupsParams) =>
    httpClient.get<CompositionGroup[]>(`/compositiongroups/search`, {
      params,
    }),
  locateGroup: (params: LocateCompositionGroupParams) =>
    httpClient.get<CompositionGroup>(`/compositiongroups/locate`, {
      params,
    }),
  archive: (id: number) =>
    httpClient.put<CompositionGroup>(`compositiongroups/${id}/archive`),
  restore: (id: number) =>
    httpClient.put<CompositionGroup>(`compositiongroups/${id}/restore`),
};
