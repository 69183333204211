import {
  Flex,
  Button,
  Input,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { ContactAddToType } from '@texas/api/endpoints/contactsApi';
import { TemplateCard } from '@texas/components/article/variant/compositions/shared/composition/TemplateCard';
import {
  nodeGroupIsPopulated,
  formatNodeGroup,
} from '@texas/utils/helpers/nodeGroupHelpers';
import { CopyPreviousValue } from './CopyPreviousValue';
import { useTranslation } from 'react-i18next';
import {
  GeneratedProductApproval,
  GeneratedProductApprovalState,
} from '../../../types';
import { ContactsModal } from '@texas/components/contacts/ContactsModal';
import { useProdutApprovalWizardState } from '../../../context';
import { CompositionGroupView } from '@texas/components/article/variant/compositions/shared/group/CompositionGroupView';
import { Icons } from '@texas/components/shared/Icons';
import { validProductApproval } from './shared';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';

export function PreviewProductApproval({
  productApproval,
  selectedKey,
  onHandle,
  previousReleventPaRef,
}: {
  productApproval: GeneratedProductApproval;
  selectedKey: string;
  onHandle: (key: string, state: GeneratedProductApprovalState) => void;
  previousReleventPaRef: GeneratedProductApproval | null | undefined;
}) {
  const { t } = useTranslation();
  const { data, setData } = useProdutApprovalWizardState();
  const {
    isOpen: isSupplierAddContactOpen,
    onClose: onSupplierAddContactClose,
    onOpen: onSupplierAddContactOpen,
  } = useDisclosure();
  const {
    isOpen: isCustomerAddContactOpen,
    onClose: onCustomerAddContactClose,
    onOpen: onCustomerAddContactOpen,
  } = useDisclosure();

  const isValid = validProductApproval(productApproval);

  return (
    <Flex flexDirection="column">
      <ContactsModal
        mode="select"
        filterOn={ContactAddToType.Supplier}
        addTo={ContactAddToType.Supplier}
        referenceId={productApproval.supplier.id}
        onClose={() => {
          onSupplierAddContactClose();
        }}
        isOpen={isSupplierAddContactOpen}
        onSelect={(c) => {
          setData((d) => ({
            ...d,
            generatedProductApprovals: {
              ...d.generatedProductApprovals,
              [selectedKey]: {
                ...d.generatedProductApprovals[selectedKey],
                supplierContact: { name: c.name, id: c.id },
              },
            },
          }));
          onSupplierAddContactClose();
        }}
      />
      <ContactsModal
        mode="select"
        filterOn={ContactAddToType.Customer}
        addTo={ContactAddToType.Customer}
        referenceId={data.customerId}
        onClose={() => {
          onCustomerAddContactClose();
        }}
        isOpen={isCustomerAddContactOpen}
        onSelect={(c) => {
          setData((d) => ({
            ...d,
            generatedProductApprovals: {
              ...d.generatedProductApprovals,
              [selectedKey]: {
                ...d.generatedProductApprovals[selectedKey],
                customerContact: { name: c.name, id: c.id },
              },
            },
          }));
          onCustomerAddContactClose();
        }}
      />

      {selectedKey.length === 0 ? (
        <Text>{t('wizard.productApproval.noSelected')}</Text>
      ) : (
        <>
          <Text variant="main" pb={2}>
            {productApproval.supplier.name} -{' '}
            {productApproval.branch.identifier}
          </Text>
          <Flex gap={2}>
            <Flex flexDir="column" w={80}>
              {nodeGroupIsPopulated(data.articleNodes) && (
                <>
                  <TexasFormLabel pt={2}>{t('general.cell')}</TexasFormLabel>
                  <Text>
                    {formatNodeGroup(
                      data.articleNodes!.nodeXValues[
                        productApproval.cell!.xIndex
                      ],
                      data.articleNodes!.nodeYValues[
                        productApproval.cell!.yIndex
                      ],
                    )}
                  </Text>
                </>
              )}
              <TexasFormLabel pt={2}>
                {t('productApproval.externalItemCode')}
              </TexasFormLabel>
              <Input
                variant="outline"
                padding="8px 4px"
                placeholder="Item code"
                value={productApproval.supplierExternalItemCode}
                onChange={(e) => {
                  setData((d) => ({
                    ...d,
                    generatedProductApprovals: {
                      ...d.generatedProductApprovals,
                      [selectedKey]: {
                        ...d.generatedProductApprovals[selectedKey],
                        supplierExternalItemCode: e.target.value,
                      },
                    },
                  }));
                }}
              />
              <CopyPreviousValue
                previousPa={previousReleventPaRef}
                value={previousReleventPaRef?.supplierExternalItemCode}
                onCopy={(pa) => {
                  setData((d) => ({
                    ...d,
                    generatedProductApprovals: {
                      ...d.generatedProductApprovals,
                      [selectedKey]: {
                        ...d.generatedProductApprovals[selectedKey],
                        supplierExternalItemCode: pa.supplierExternalItemCode,
                      },
                    },
                  }));
                }}
              />
              <TexasFormLabel pt={2}>
                {t('productApproval.supplierNote')}
              </TexasFormLabel>
              <>
                <Textarea
                  value={productApproval.supplierNote}
                  onChange={(e) => {
                    setData((d) => ({
                      ...d,
                      generatedProductApprovals: {
                        ...d.generatedProductApprovals,
                        [selectedKey]: {
                          ...d.generatedProductApprovals[selectedKey],
                          supplierNote: e.target.value,
                        },
                      },
                    }));
                  }}
                />
                <CopyPreviousValue
                  previousPa={previousReleventPaRef}
                  value={previousReleventPaRef?.supplierNote}
                  onCopy={(pa) => {
                    setData((d) => ({
                      ...d,
                      generatedProductApprovals: {
                        ...d.generatedProductApprovals,
                        [selectedKey]: {
                          ...d.generatedProductApprovals[selectedKey],
                          supplierNote: pa.supplierNote,
                        },
                      },
                    }));
                  }}
                />
              </>
              <TexasFormLabel pt={2}>
                {t('suppliers.supplierContact')}
              </TexasFormLabel>
              <Button
                variant="texas-light"
                my={1}
                width="full"
                onClick={() => onSupplierAddContactOpen()}
              >
                {productApproval.supplierContact?.name ??
                  t('contacts.selectContact')}
              </Button>
              <CopyPreviousValue
                previousPa={previousReleventPaRef}
                value={previousReleventPaRef?.supplierContact?.name}
                onCopy={(pa) => {
                  setData((d) => ({
                    ...d,
                    generatedProductApprovals: {
                      ...d.generatedProductApprovals,
                      [selectedKey]: {
                        ...d.generatedProductApprovals[selectedKey],
                        supplierContact: pa.supplierContact,
                      },
                    },
                  }));
                }}
              />
              <TexasFormLabel pt={2}>
                {t('customer.customerContact')}
              </TexasFormLabel>
              <Button
                variant="texas-light"
                my={1}
                width="full"
                onClick={() => onCustomerAddContactOpen()}
              >
                {productApproval.customerContact?.name ??
                  t('contacts.selectContact')}
              </Button>

              {productApproval.state ===
                GeneratedProductApprovalState.Unhandled && (
                <>
                  <Button
                    isDisabled={isValid !== null}
                    mt={4}
                    variant="texas-solid"
                    rightIcon={<Icons.Checkmark boxSize={5} />}
                    onClick={(e) => {
                      onHandle(
                        selectedKey,
                        GeneratedProductApprovalState.Accepted,
                      );
                      e.stopPropagation();
                    }}
                  >
                    {t('wizard.productApproval.ready')}
                  </Button>
                  {isValid === 'contact_missing' && (
                    <Text pt={1} color="gray.400" textAlign="center">
                      {t('wizard.productApproval.selectContacts')}
                    </Text>
                  )}
                  {isValid === 'composition_missing' && (
                    <Text pt={1} color="gray.400" textAlign="center">
                      {t('wizard.productApproval.noComposition')}
                    </Text>
                  )}
                </>
              )}
            </Flex>
            {productApproval.compositionGroup !== null ? (
              <CompositionGroupView
                componentSettings={{
                  compositionArchiveRestore: 'hidden',
                  compositionEdit: 'hidden',
                  compositionGroupDots: 'hidden',
                  expander: 'hidden',
                }}
                compositionGroupId={productApproval.compositionGroup.id}
                variantId={productApproval.compositionGroup.variantId}
                productGroupId={0}
                baseTemplateId={null}
              />
            ) : (
              <TemplateCard exists={false}>
                <Text p={2} textAlign="center">
                  {t('composition.noComposition')}
                </Text>
              </TemplateCard>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
}
