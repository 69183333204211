import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  IconButton,
  Tooltip,
  useToast,
  Flex,
  Box,
  Text,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  ModalHeader,
  Checkbox,
  Textarea,
  FormControl,
} from '@chakra-ui/react';
import {
  AcceptPaRequest,
  productApprovalsApi,
  ProductApprovalState,
  ProductApprovalRejectReason,
} from '@texas/api/endpoints/productApprovalsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { Icons } from '@texas/components/shared/Icons';
import { VerifyButton } from '@texas/components/shared/verifyButton/VerifyButton';
import { Locale } from '@texas/i18n/types';
import { fadeInLeftAnimation } from '@texas/resources/animations/animations';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { useEffect, useState } from 'react';
import { getEnumNamesAndValues } from '@texas/utils/helpers/enumHelpers';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useTranslation } from 'react-i18next';
import { ServerError } from '@texas/types';
import { getStateReasonOption, rejectReasonAsFriendlyName } from './shared';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ProductApprovalInfo } from './shared/ProductApprovalInfo';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';

export function AcceptModal({
  productApprovalId,
  branchId,
  variantId,
  onAccept,
}: {
  productApprovalId: number;
  branchId: number;
  variantId: number;
  onAccept: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <Tooltip label={t('productApproval.acceptTooltip')}>
        <IconButton
          variant="texas-light"
          onClick={onOpen}
          size="sm"
          icon={<Icons.Checkmark boxSize="4" />}
          aria-label={t('productApproval.acceptTooltip')}
        />
      </Tooltip>

      <Modal isCentered={true} size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalInner
            onAccept={onAccept}
            productApprovalId={productApprovalId}
            onClose={onClose}
            branchId={branchId}
            variantId={variantId}
          />
        </ModalContent>
      </Modal>
    </>
  );
}

interface ModalInnerProps {
  productApprovalId: number;
  branchId: number;
  variantId: number;
  onAccept: () => void;
  onClose: () => void;
}

function ModalInner({
  productApprovalId,
  variantId,
  branchId,
  onClose,
  onAccept,
}: ModalInnerProps) {
  const toast = useToast();
  const {
    data: productApproval,
    refetch: refetchProductApproval,
    loading: productApprovalLoading,
    error: getProductApprovalError,
  } = useApiResource(productApprovalsApi.get);
  const {
    data: productApprovals,
    refetch: refetchAll,
    loading: refetchAllLoading,
    error: refetchAllError,
  } = useApiResource(productApprovalsApi.getForVariant);

  const [rejected, setRejected] = useState<
    { note: string; toReject: boolean; id: number }[]
  >([]);

  const { t } = useTranslation();

  const {
    request: acceptRequest,
    loading: acceptLoading,
    error: acceptError,
  } = useApiRequest(productApprovalsApi.acceptPa);

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<AcceptPaRequest>();

  const onSubmit = async (data: AcceptPaRequest) =>
    await request(
      acceptRequest,
      [productApprovalId, data],
      () => {
        toast({
          title: t('productApproval.accepted'),
          status: 'success',
          isClosable: true,
        });
        onAccept();
        onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('general.actionFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'rejectPendingPas',
    keyName: 'array-id',
  });

  useEffect(() => {
    refetchProductApproval(productApprovalId);
    refetchAll(variantId, {
      state: ProductApprovalState.Pending,
      branchId: branchId,
    });
  }, [
    branchId,
    productApprovalId,
    refetchProductApproval,
    refetchAll,
    variantId,
  ]);

  useEffect(() => {
    if (!productApprovals) return;
    setRejected(
      productApprovals
        .filter((x) => x.id !== productApprovalId)
        .map((x) => ({ toReject: false, note: '', id: x.id })),
    );
  }, [productApprovalId, productApprovals]);

  useEffect(() => {
    for (let i = 0; i < rejected.length; i++) {
      const r = rejected[i];

      if (r.toReject) {
        if (fields.some((f) => f.id === r.id)) {
          continue;
        }

        appendPaData(r.id);
        continue;
      }

      const index = fields.findIndex((f) => f.id === r.id);
      if (index === -1) {
        continue;
      }

      remove(index);
    }

    function appendPaData(id: number) {
      append({ id: id, note: '', reasons: [] });
    }
  }, [append, fields, rejected, remove]);

  const allChecked = rejected.every((x) => x.toReject);

  return (
    <>
      <ModalHeader
        borderBottom="1px solid"
        borderColor="texas.bg.700"
        _light={{ borderColor: 'gray.50', color: 'black' }}
      >
        {t('general.accept')}
      </ModalHeader>
      <Box>
        {getProductApprovalError && (
          <ErrorDetails error={getProductApprovalError} />
        )}
        {acceptError && <ErrorDetails error={acceptError} />}
        <Flex>
          <Box
            minW="35%"
            maxW="35%"
            p={4}
            bg="texas.bg.700"
            _light={{ bg: 'gray.50' }}
          >
            {productApprovalLoading && <Spinner />}
            {productApproval && (
              <>
                <Flex
                  animation={fadeInLeftAnimation(600)}
                  alignItems="center"
                  gap={1}
                  pb={2}
                >
                  <Text fontFamily="Barlow" fontSize="xl">
                    {t('productApproval.willBeAccepted')}
                  </Text>
                  <Icons.ArrowDownRight boxSize="4" />
                </Flex>

                <ProductApprovalInfo
                  pa={productApproval}
                  state={ProductApprovalState.Accepted}
                />
              </>
            )}
          </Box>

          <Flex flexGrow={1}>
            <Flex flexDir="column" py="12" flexGrow={1} p={4} gap={2}>
              {refetchAllLoading && <Spinner />}
              {productApprovals?.filter((x) => x.id !== productApprovalId)
                ?.length === 0 && !refetchAllLoading ? (
                <Text>
                  {t('productApproval.noOtherPending', {
                    branch: productApproval?.branchName,
                  })}
                </Text>
              ) : (
                <>
                  {refetchAllError && <ErrorDetails error={refetchAllError} />}

                  {!refetchAllLoading && (
                    <>
                      <Flex alignItems="center" gap={1} pb={2}>
                        <Text fontFamily="Barlow" fontSize="md">
                          {t('productApproval.handlePendingPas', {
                            branch: productApproval?.branchName,
                          })}
                        </Text>
                        <Icons.ArrowDownRight boxSize="4" />
                      </Flex>
                      <form>
                        <TableContainer flexGrow={1}>
                          <Table variant="stickyHeader">
                            <Thead px={2}>
                              <Tr>
                                <Th w="25%">
                                  {t('productApproval.productApproval')}
                                </Th>
                                <Th>
                                  <Flex gap={2} alignItems="center">
                                    <Checkbox
                                      isChecked={allChecked}
                                      isIndeterminate={
                                        rejected.some((x) => x.toReject) &&
                                        !allChecked
                                      }
                                      onChange={(e) => {
                                        setRejected((r) =>
                                          r.map((x) => ({
                                            ...x,
                                            toReject: e.target.checked,
                                          })),
                                        );
                                      }}
                                    />
                                    <Text>{t('productApproval.reject')}</Text>
                                  </Flex>
                                </Th>
                                <Th w="30%">{t('productApproval.reason')}</Th>
                                <Th w="30%">{t('general.note')}</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {productApprovals && rejected.length > 0 && (
                                <>
                                  {productApprovals
                                    .filter((x) => x.id !== productApprovalId)
                                    .map((x, i) => {
                                      const fieldIndex = fields.findIndex(
                                        (f) => f.id === rejected[i].id,
                                      );
                                      return (
                                        <Tr key={x.id}>
                                          <Td>
                                            <Text
                                              fontWeight="bold"
                                              fontFamily="barlow"
                                              pb={2}
                                            >
                                              {x.formatNumber}
                                            </Text>
                                            <Text whiteSpace="normal">
                                              {x.supplierName}
                                            </Text>
                                            <Text whiteSpace="normal">
                                              {t('composition.version', {
                                                nr: x.compositionGroupVersion,
                                              })}
                                            </Text>
                                            <Text>
                                              {t('productApproval.issued', {
                                                date: formatDate(
                                                  Locale.En,
                                                  x.created,
                                                ),
                                              })}
                                            </Text>
                                          </Td>
                                          <Td>
                                            <Checkbox
                                              onChange={(e) => {
                                                setRejected((r) =>
                                                  r.map((x, k) => {
                                                    if (i === k) {
                                                      return {
                                                        ...x,
                                                        toReject:
                                                          e.target.checked,
                                                      };
                                                    }
                                                    return x;
                                                  }),
                                                );
                                              }}
                                              isChecked={rejected[i].toReject}
                                              iconSize="1rem"
                                            />
                                          </Td>
                                          <Td>
                                            {rejected[i].toReject &&
                                              fieldIndex !== -1 && (
                                                <>
                                                  <FormControl
                                                    isRequired={true}
                                                    isInvalid={
                                                      !!errors
                                                        .rejectPendingPas?.[
                                                        fieldIndex
                                                      ]?.message
                                                    }
                                                  >
                                                    <Controller
                                                      name={`rejectPendingPas.${fieldIndex}.reasons`}
                                                      control={control}
                                                      rules={{
                                                        required: true,
                                                        minLength: {
                                                          value: 1,
                                                          message: t(
                                                            'productApproval.reasonsRequired',
                                                            { count: 1 },
                                                          ),
                                                        },
                                                      }}
                                                      render={({ field }) => (
                                                        <TexasSelect
                                                          {...field}
                                                          menuPortalTarget={
                                                            document.body
                                                          }
                                                          styles={{
                                                            menuPortal: (
                                                              baseStyles,
                                                            ) => ({
                                                              ...baseStyles,
                                                              zIndex: 1401,
                                                            }),
                                                          }}
                                                          isMulti={true}
                                                          closeMenuOnSelect={
                                                            false
                                                          }
                                                          value={field.value.map(
                                                            (x) =>
                                                              getStateReasonOption(
                                                                t,
                                                                x,
                                                              ),
                                                          )}
                                                          onChange={(e) =>
                                                            field.onChange(
                                                              e.map(
                                                                (x) => x.value,
                                                              ),
                                                            )
                                                          }
                                                          isClearable={true}
                                                          options={getEnumNamesAndValues(
                                                            ProductApprovalRejectReason,
                                                          ).map((s) => {
                                                            return {
                                                              label:
                                                                rejectReasonAsFriendlyName(
                                                                  t,
                                                                  s.value as ProductApprovalRejectReason,
                                                                ),
                                                              value: s.value,
                                                            };
                                                          })}
                                                        />
                                                      )}
                                                    />

                                                    <ErrorLabel
                                                      text={
                                                        errors
                                                          .rejectPendingPas?.[
                                                          fieldIndex
                                                        ]?.message
                                                      }
                                                    />
                                                  </FormControl>
                                                </>
                                              )}
                                          </Td>
                                          <Td>
                                            {rejected[i].toReject &&
                                              fieldIndex !== -1 && (
                                                <>
                                                  <Textarea
                                                    {...register(
                                                      `rejectPendingPas.${fieldIndex}.note` as const,
                                                      {
                                                        maxLength: {
                                                          value: 200,
                                                          message: t(
                                                            'errors.maxLength',
                                                            {
                                                              count: 200,
                                                            },
                                                          ),
                                                        },
                                                      },
                                                    )}
                                                    minHeight={6}
                                                    height="auto"
                                                    padding={1}
                                                    width="100%"
                                                    borderRadius={3}
                                                    borderColor="gray"
                                                    _hover={{
                                                      borderColor:
                                                        'hsl(0, 0%, 70%)',
                                                    }}
                                                  />
                                                </>
                                              )}
                                          </Td>
                                        </Tr>
                                      );
                                    })}
                                </>
                              )}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </form>
                    </>
                  )}
                </>
              )}

              <Flex ml="auto" mt="auto">
                <Button mr={3} onClick={onClose}>
                  {t('general.close')}
                </Button>
                <VerifyButton
                  tooltipLabel={
                    isValid ? undefined : t('productApproval.missingReasons')
                  }
                  buttonProps={{
                    leftIcon: <Icons.CheckmarkDecagram boxSize={5} />,
                    isDisabled: !isValid,
                    isLoading: acceptLoading,
                  }}
                  onVerified={() => {
                    handleSubmit(onSubmit)();
                  }}
                  label={t('productApproval.accept', {
                    count: fields.length,
                  })}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
