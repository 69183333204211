import { Flex, Tooltip, Text, Box, Link } from '@chakra-ui/react';
import { ProductApprovalState } from '@texas/api/endpoints/productApprovalsApi';
import { Icons } from '@texas/components/shared/Icons';
import { useContext } from 'react';
import { GroupContext } from './CompositionGroupView';
import { useTranslation } from 'react-i18next';
import { clientEndpoints } from '@texas/clientEndpoints';

export function CompositionGroupHeader({
  customTitle,
}: {
  customTitle?: string;
}) {
  const { group, productApprovalsCount, componentSettings } =
    useContext(GroupContext)!;
  const { t } = useTranslation();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      p={2}
      bg="texas.bg.900"
      _light={{ bg: 'gray.100' }}
      borderRadius="md"
      opacity={group?.exists ? 1 : 0.6}
    >
      <Flex flexDir="column">
        <Text fontSize="sm" fontWeight="bold">
          {customTitle}
        </Text>
        {group?.exists ? (
          <Text fontSize="smaller">
            {t('composition.version', { nr: group.version })}
          </Text>
        ) : (
          <Text variant="sub">{t('general.noData')}</Text>
        )}
      </Flex>
      {componentSettings?.paStates !== 'hidden' && (
        <Flex gap={3}>
          {productApprovalsCount(ProductApprovalState.Pending) > 0 && (
            <Tooltip
              label={t('composition.pendingPa', {
                count: productApprovalsCount(ProductApprovalState.Pending),
              })}
            >
              <Box>
                <Link
                  href={clientEndpoints.articleProductApproval(
                    group!.articleId,
                    {
                      branchIds: group?.branchId ? [group.branchId] : undefined,
                      state: ProductApprovalState[
                        ProductApprovalState.Pending
                      ] as keyof typeof ProductApprovalState,
                      supplierIds: group?.supplierId
                        ? [group.supplierId]
                        : undefined,
                    },
                  )}
                >
                  <Flex alignItems="center" gap={1}>
                    <Icons.Clock />
                    <Text fontWeight="bold">
                      {productApprovalsCount(ProductApprovalState.Pending)}
                    </Text>
                  </Flex>
                </Link>
              </Box>
            </Tooltip>
          )}
          {productApprovalsCount(ProductApprovalState.Accepted) > 0 && (
            <Tooltip
              label={t('composition.acceptedPa', {
                count: productApprovalsCount(ProductApprovalState.Accepted),
              })}
            >
              <Box>
                <Link
                  color="texas.sand.100"
                  href={clientEndpoints.articleProductApproval(
                    group!.articleId,
                    {
                      branchIds: group?.branchId ? [group.branchId] : undefined,
                      state: ProductApprovalState[
                        ProductApprovalState.Accepted
                      ] as keyof typeof ProductApprovalState,
                      supplierIds: group?.supplierId
                        ? [group.supplierId]
                        : undefined,
                    },
                  )}
                >
                  <Flex
                    _light={{ color: 'texas.burntSienna.500' }}
                    alignItems="center"
                    gap={1}
                  >
                    <Icons.CheckCircle />
                    <Text fontWeight="bold">
                      {productApprovalsCount(ProductApprovalState.Accepted)}
                    </Text>
                  </Flex>
                </Link>
              </Box>
            </Tooltip>
          )}
        </Flex>
      )}
    </Flex>
  );
}
